// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "q_qx d_bz";
export var datasheetRowWrapper = "q_lx d_lx d_bG";
export var datasheetLeftWrapper = "q_qy d_bw d_bL";
export var datasheetWrapperFull = "q_qz d_cz";
export var datasheetWrapperFullLeft = "q_nK d_cz d_bw d_bL d_bz d_bQ";
export var contentWrapper = "q_mC d_ly";
export var contentWrapperCenter = "q_qB d_lD";
export var datasheetLeftWrapperCenter = "q_qC d_lB d_bw d_bz d_bL d_bF";
export var datasheetImageCenterWrapper = "q_lv d_lv d_s d_cr";
export var datasheetRightWrapperCenter = "q_qD d_lz";
export var datasheetFooter = "q_lC d_lC";
export var alignLeft = "q_p6 d_dr";
export var alignCenter = "q_bL d_ds";
export var alignRight = "q_p7 d_dt";
export var datasheetInnerWrapperNewLeft = "q_qF d_fb d_L d_W d_bw d_bL d_df";
export var datasheetInnerWrapperLeft = "q_qG d_d9 d_L d_W d_bw d_bL d_b2 d_df d_c6";
export var datasheetInnerWrapperRight = "q_qH d_fb d_L d_W d_bw d_bL d_df";
export var datasheetRowWrapperReverse = "q_qJ d_bG";
export var datasheetSubtitle = "q_qK d_ct";
export var tableRow = "q_qL";
export var cell = "q_qM";
export var tableRowWrapper = "q_qN d_s d_dr";
export var tableHeadingCell = "q_qP";
export var tableHeading = "q_qQ";
export var tableRowStriped = "q_qR";
export var tableRowHeading = "q_qS";
export var dataTable = "q_qT d_s";
export var imageWrapper = "q_qV d_fc d_W";
export var imageWrapperFull = "q_qW d_D d_s d_bb d_W";
export var imageWrapperIcon = "q_qX";
export var titleMargin = "q_qY d_cp";
export var datasheetInnerInnerWrapper = "q_qZ d_s";
export var hasLabels = "q_q0";
export var label = "q_q1";
export var SmallSmall = "q_q2 y_q2 y_sq y_sN";
export var SmallNormal = "q_q3 y_q3 y_sq y_sP";
export var SmallLarge = "q_q4 y_q4 y_sq y_sL";