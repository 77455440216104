// extracted by mini-css-extract-plugin
export var alignDiscLeft = "s_rh d_fl d_bC d_dr";
export var alignLeft = "s_p6 d_fl d_bC d_dr";
export var alignDiscCenter = "s_rj d_fm d_bz d_ds";
export var alignCenter = "s_bL d_fm d_bz d_ds";
export var alignDiscRight = "s_rk d_fn d_bD d_dt";
export var alignRight = "s_p7 d_fn d_bD d_dt";
export var footerContainer = "s_rl d_dS d_bS";
export var footerContainerFull = "s_rm d_dQ d_bS";
export var footerHeader = "s_kc d_kc";
export var footerTextWrapper = "s_rn d_s";
export var footerDisclaimerWrapper = "s_kk d_kk d_cg";
export var badgeWrapper = "s_rp d_s d_bw d_bL d_bB d_bF";
export var footerDisclaimerRight = "s_kl d_kl d_bw";
export var footerDisclaimerLeft = "s_km d_km d_bw";
export var verticalTop = "s_rq d_bw d_bF d_bK d_bH";
export var firstWide = "s_rr";
export var disclaimer = "s_rs";
export var socialDisclaimer = "s_rt";
export var left = "s_rv";
export var wide = "s_rw d_cv";
export var right = "s_rx d_bG";
export var line = "s_fg d_fh d_cr";
export var badgeDisclaimer = "s_ry d_by d_bL d_bF";
export var badgeContainer = "s_rz d_bw d_bD d_bL";
export var badge = "s_rB";
export var padding = "s_rC d_c4";
export var end = "s_rD d_bD";
export var linkWrapper = "s_rF d_dx";
export var link = "s_mF d_dx";
export var colWrapper = "s_rG d_ct";
export var media = "s_rH d_bt d_dv";
export var itemRight = "s_rJ";
export var itemLeft = "s_rK";
export var itemCenter = "s_rL";
export var exceptionWeight = "s_rM y_sS";