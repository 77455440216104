// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_rN d_gs d_cp";
export var heroHeaderCenter = "t_gt d_gt d_cp d_ds";
export var heroHeaderRight = "t_gv d_gv d_cp d_dt";
export var heroParagraphLeft = "t_rP d_gp d_cs";
export var heroParagraphCenter = "t_gq d_gq d_cs d_ds";
export var heroParagraphRight = "t_gr d_gr d_cs d_dt";
export var heroBtnWrapperLeft = "t_rQ d_dZ d_dY d_s d_bw d_bC";
export var heroBtnWrapperCenter = "t_rR d_d0 d_dY d_s d_bw d_bz";
export var heroBtnWrapperRight = "t_rS d_d1 d_dY d_s d_bw d_bD";
export var overlayBtnWrapper = "t_rT d_gn d_X d_4 d_5 d_6 d_bk d_b8";
export var design4 = "t_rV d_gm d_X d_4 d_5 d_bk";
export var heroExceptionSmall = "t_rW y_rW";
export var heroExceptionNormal = "t_rX y_rX";
export var heroExceptionLarge = "t_rY y_rY";
export var Title1Small = "t_rZ y_rZ y_sq y_sr";
export var Title1Normal = "t_r0 y_r0 y_sq y_ss";
export var Title1Large = "t_r1 y_r1 y_sq y_st";
export var BodySmall = "t_r2 y_r2 y_sq y_sK";
export var BodyNormal = "t_r3 y_r3 y_sq y_sL";
export var BodyLarge = "t_r4 y_r4 y_sq y_sM";